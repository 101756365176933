import React from "react"
import { graphql } from "gatsby"
import PropertyDetailSection from '../propertyDetail/propertyDetailSection'
import Layout from "../layout"
import { injectIntl} from "gatsby-plugin-intl";
import "./Print.css"

import propertiesData from "../../../build_data/properties.json";
import zonesData from "../../../build_data/zones.json";

const Print = ({ data,intl, pageContext }) => ({

    getLanguageDescription() {
      var property = propertiesData.filter(p => p.id === pageContext.id)[0];
      const language = intl.locale;
      return property.propertiesdescription ? property.propertiesdescription[language] : '';
    },

    getProductTitle(){
      var property = propertiesData.filter(p => p.id === pageContext.id)[0];
      return intl.formatMessage({ id:'type-'+ property.type}) + 
                        (property.complex.length > 0 ? " - " : "") + 
                        (property.complex.length > 0 ? property.complex : "") +                    
                        (property.Zone != "Select_Zone" ? " - " : "") +
                        (property.Zone != "Select_Zone" ? intl.formatMessage({ id:'zones.'+ property.Zone}) : "") + 
                        "  " + 
                        (intl.formatMessage({ id:'municipality.'+ property.municipality}));
    },

    render(){
      var property =  propertiesData.filter(p => p.id === pageContext.id)[0];
      var zone = zonesData.filter(x => x.Name === property.Zone)[0];
      const language = intl.locale;

      return (

        <div id="print-page" className="container padding-top-printing">
          <Layout isPrinting={true}>
            {/* <div className="row">
            <Img
              fixed={data.print_logo.childImageSharp.fixed}/> 
            </div> */}
            <div className="row">
              <div className="col-lg-12 single-list-page print">
                <PropertyDetailSection property={property} language={language} zone={zone} isPrinting={true} intl={intl} languageDescription={this.getLanguageDescription()} propertyTitle={this.getProductTitle()}></PropertyDetailSection>
              </div>
            </div>

            <div className="print author-card row">
              <div className="author-contact">
                <p className="offset-1 col-4 author-card-title">{intl.formatMessage({ id:'Contact-Info' })}</p>
                <p className="offset-1 col-2"><i className="fa fa-phone"></i><a href="tel:+34922794214">+34-922794214</a></p>
                <p className="col-2"><i className="fa fa-whatsapp"></i><a href="tel:+34610168751">+34-610168751</a></p>
                <p className="col-2"><i className="fa fa-envelope"></i>info@visaverde.com</p>
              </div>
            </div>
          </Layout>
        </div>
      )
    }
})

export default injectIntl(Print)

export const query = graphql`
  query {
    print_logo: file(relativePath: { eq: "print_logo.png" }) {
      childImageSharp {
          fixed(width: 1200) {
          ...GatsbyImageSharpFixed
          }
      }
    }
  }
`